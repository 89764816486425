.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.center {
  text-align: center;
  margin: 0 auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-img-top {
  max-height: 20em;
}

.no-padding {
  padding: 0px !important;
}

.row {
  margin-right: 0px;
}

.btn-primary {
  background-color:  #FF6B01;
  border: 1px solid  #FF6B01;
}

.btn-primary:hover {
  background-color:  #FF6B01;
  border: 1px solid  #FF6B01;
}

.slide-next-animation {
  animation-name: App-logo-spin;
  animation-duration: 4s;
}

.navbar {
  box-shadow: 1px -4px 13px 0px black;
}